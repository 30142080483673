<template>
    <v-card>
        <v-card-title>
            <h3>Feedback</h3>
        </v-card-title>
        <v-card-text>
            <p>
                Use the button below to launch the feedback form.
            </p>
            <v-btn
                color="primary"
                dark
                @click="launchFeedbackPage"
                v-if="windowUrl"
                >Provide Feedback</v-btn
            >
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'feedback',
    data: () => ({
        keywords: '',
        dialog: false,
    }),
    methods: {
        launchFeedbackPage() {
            this.iFrameSrc = this.windowUrl || 'https://google.com'
            window.open(
                this.iFrameSrc,
                'popup',
                'width=700,height=760,left=250'
            )
        },
    },
    computed: {
        windowUrl() {
            if (this.$store.getters.userForPortalAccess.client) {
                return this.$store.getters.userForPortalAccess.client
                    .feedback_iframe_target
            } else {
                return null
            }
        },
    },
}
</script>
